<template>
    <div class="wrapper">
      <div class="headtop"></div>
  <header id="header" class="fixed-top d-flex align-items-center">
    <div id="logoh" class="container d-flex align-items-center" style="background-color: #111;">
      <div class="logo" id="logoi">
        <h1 class="text-light">
          <a href="/"><img src="@/assets/images/logo-lanscape.jpg" width="207" height="87" alt="" class="img-fluid"></a>
        </h1>
      </div>

      <nav class="nav-menu d-none d-lg-block" style="margin-right: 70px;">
        <ul>
          <li class="active"><a href="/">ໜ້າຫຼັກ</a></li>
          <li><a href="#">ຜົນອອກເລກ</a></li>
          <li><a href="#">ເງື່ອນໄຂຮັບລາງວັນ</a></li>
          <li><a href="#">ໂປຼໂມຊັ້ນ</a></li>
          <li><a href="#">ວິທີການສະໝັກ</a></li>

          <li class="get-started"><a href="#">ສະໝັກຕົວແທນຂາຍ</a></li>
        </ul>
      </nav>
    </div>
  </header>

  
  <section id="banner" class="align-items-center">
    <div class="container" style="padding-left: 0px !important; padding-right: 0px !important;">
      <div class="row">
        <div class="col-lg-12 pt-10 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <div class="container" style="padding-left: 0px !important; padding-right: 0px !important;">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div class="carousel-inner" style="padding-top: 25px;">
                <div class="carousel-item active">
                  <img class="d-block w-100" src="@/assets/images/Banner-slide-1.jpg" alt="First slide">
                </div>
                <div class="carousel-item">
                  <img class="d-block w-100" src="@/assets/images/Banner-slide-2.jpg" alt="Second slide">
                </div>
                <div class="carousel-item">
                  <img class="d-block w-100" src="@/assets/images/Banner-slide-3.jpg" alt="Third slide">
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>

  <section id="team" class="team section-bg">
    <div class="container">
      <div class="section-title" data-aos="fade-up">
        <h2 id="dmy1" style="color: var(--fonts-main-color);"></h2>
        <h2 id="dmy2" style="color: var(--fonts-main-color);">ຫວຍລາວດິຈິຕອນ 12 ราศี VIP ວັນທີ</h2>
      </div>
      <div class="row">
          <div class="col-lg-3 col-md-1 col-sm-12"></div>
          <div class="col-lg-12 col-md-12 col-sm-12" style="text-align: center;" id="showbox">
            <div class="sl_1 odometer odometer-auto-theme" id="sl_1" data=""><div class="odometer-inside"><span class="odometer-digit"><span class="odometer-digit-spacer">8</span><span class="odometer-digit-inner"><span class="odometer-ribbon"><span class="odometer-ribbon-inner"><span class="odometer-value" id="sl_1_value">0</span></span></span></span></span></div></div>
              <div class="" id="rl_1" data=""></div>
            <div class="sl_2 odometer odometer-auto-theme" id="sl_2" data=""><div class="odometer-inside"><span class="odometer-digit"><span class="odometer-digit-spacer">8</span><span class="odometer-digit-inner"><span class="odometer-ribbon"><span class="odometer-ribbon-inner"><span class="odometer-value" id="sl_2_value">0</span></span></span></span></span></div></div>
              <div class="" id="rl_2" data=""></div>
            <div class="sl_3 odometer odometer-auto-theme" id="sl_3" data=""><div class="odometer-inside"><span class="odometer-digit"><span class="odometer-digit-spacer">8</span><span class="odometer-digit-inner"><span class="odometer-ribbon"><span class="odometer-ribbon-inner"><span class="odometer-value" id="sl_3_value">0</span></span></span></span></span></div></div>
              <div class="" id="rl_3" data=""></div>
            <div class="sl_4 odometer odometer-auto-theme" id="sl_4" data=""><div class="odometer-inside"><span class="odometer-digit"><span class="odometer-digit-spacer">8</span><span class="odometer-digit-inner"><span class="odometer-ribbon"><span class="odometer-ribbon-inner"><span class="odometer-value" id="sl_4_value">0</span></span></span></span></span></div></div>
              <div class="" id="rl_4" data=""></div>
            <div class="sl_5 odometer odometer-auto-theme" id="sl_5" data=""><div class="odometer-inside"><span class="odometer-digit"><span class="odometer-digit-spacer">8</span><span class="odometer-digit-inner"><span class="odometer-ribbon"><span class="odometer-ribbon-inner"><span class="odometer-value" id="sl_5_value">0</span></span></span></span></span></div></div>
              <div class="" id="rl_5" data=""></div>
            <div class="sl_6 odometer odometer-auto-theme" id="sl_6" data=""><div class="odometer-inside"><span class="odometer-digit"><span class="odometer-digit-spacer">8</span><span class="odometer-digit-inner"><span class="odometer-ribbon"><span class="odometer-ribbon-inner"><span class="odometer-value" id="sl_6_value">0</span></span></span></span></span></div></div>
              <div class="" id="rl_6" data=""></div>
          </div>
          <div class="col-lg-3 col-md-1 col-sm-12"></div>
      </div> 
    </div>
  </section>

    <section id="pricing" class="pricing" style="
    padding-top: 40px;
    padding-bottom: 40px;">
      <div class="container">

        <div class="row">
          <div class="col-lg-12 col-md-12 mt-6 mt-md-0">
            <div style="text-align: center;">
              <h3 style="color: var(--fonts-main-color);">ລາງວັນເລກ 6 ໂຕ</h3>
            </div>
            <div class="box recommended gboxx" data-aos="zoom-in" data-aos-delay="100">
              <h1 class="gh1x" id="6digi">&nbsp;{{this.first.five}}</h1>
            </div>
            <div>
              <h3 class="gh3x" style="color:#fff">ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 400,000,000 ກີບ.</h3>
            </div>
          </div>
          <div class="text-center">
            <div class="is-divider divider clearfix" style="max-width:640px;"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-4 mt-3 mt-md-0">
            <div style="text-align: center;">
              <h3 style="color: var(--fonts-main-color);">ລາງວັນເລກ 5 ໂຕ</h3>
            </div>
            <div class="box recommended gboxx" data-aos="zoom-in" data-aos-delay="100">
              <h1 class="gh1x" id="5digi">{{(this.first && this.first.five)?this.first.five.toString().charAt(1)+this.first.five.toString().charAt(2)+this.first.five.toString().charAt(3)+this.first.five.toString().charAt(4)+this.first.five.toString().charAt(5):''}}</h1>
            </div>
            <div>
              <h3 class="gh3x">ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 40,000,000 ກີບ.</h3>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 mt-3 mt-md-0">
            <div style="text-align: center;">
              <h3 style="color: var(--fonts-main-color);">ລາງວັນເລກ 4 ໂຕ</h3>
            </div>
            <div class="box recommended gboxx" data-aos="zoom-in" data-aos-delay="100">
              <h1 class="gh1x" id="4digi">{{(this.first && this.first.five)?this.first.five.toString().charAt(2)+this.first.five.toString().charAt(3)+this.first.five.toString().charAt(4)+this.first.five.toString().charAt(5):''}}</h1>
            </div>
            <div>
              <h3 class="gh3x">ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 6,000,000 ກີບ.</h3>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 mt-3 mt-md-0">
            <div style="text-align: center;">
              <h3 style="color: var(--fonts-main-color);">ລາງວັນເລກ 3 ໂຕ</h3>
            </div>
            <div class="box recommended gboxx" data-aos="zoom-in" data-aos-delay="100">
              <h1 class="gh1x" id="3digi">{{(this.first && this.first.five)?this.first.five.toString().charAt(3)+this.first.five.toString().charAt(4)+this.first.five.toString().charAt(5):''}}</h1>
            </div>
            <div >
              <h3 class="gh3x">ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 500,000 ກີບ.</h3>
            </div>
          </div>
          <div class="col-lg-12 col-md-12"><br></div>
          <div class="offset-lg-2 col-lg-4 offset-md-2 col-md-4 mt-3 mt-md-0">
            <div style="text-align: center;" class="">
              <h3 style="color: var(--fonts-main-color);">ລາງວັນເລກ 2 ໂຕ</h3>
            </div>
            <div class="box recommended gboxx" data-aos="zoom-in" data-aos-delay="100">
              <h1 class="gh1x" id="2digi">{{(this.first && this.first.five)?this.first.five.toString().charAt(4)+this.first.five.toString().charAt(5):''}}</h1>
            </div>
            <div >
              <h3 class="gh3x">ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 60,000 ກີບ.</h3>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 mt-3 mt-md-0">
            <div style="text-align: center;">
              <h3 style="color: var(--fonts-main-color);">ລາງວັນເລກ 1 ໂຕ</h3>
            </div>
            <div class="box recommended gboxx" data-aos="zoom-in" data-aos-delay="100">
              <h1 class="gh1x" id="1digi">{{(this.first && this.first.five)?this.first.five.toString().charAt(5):''}}</h1>
            </div>
            <div >
              <h3 class="gh3x">ຊື້ຖືກ 1000 ກີບ ໄດ້ຮັບເງິນສົດ 5,000 ກີບ.</h3>
            </div>
          </div>

        </div>
      </div> 
      </section>  

    <section id="about" class="about" style="background-color: #6D6D6D;">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2 style="color: #fff;">ກວດສອບຜົນການອອກຫວຍຍ້ອນຫຼັງ</h2>
        </div>

        <div class="row content">
          <div class="offset-lg-2 col-lg-8" data-aos="fade-up" data-aos-delay="150">
            <a href="#">
              <img src="@/assets/images/Ad_4.jpg" alt="" class="img-fluid">
            </a><br><br>
          </div>
          <div class="offset-lg-3 col-lg-6" data-aos="fade-up" data-aos-delay="450">
            <table class="table table-bordered" style="background-color: #ff8a3b;">
              <thead>
                <tr>
                  <td width="40%" style="text-align: center; color: black;">
                    <strong>ວັນທີ</strong>
                  </td>
                  <td width="60%" style="text-align: center; color: black;">
                    <strong>ເລກອອກ</strong>
                  </td>
                </tr>
              </thead>
              <tbody id="lastlottery">
                <tr v-for="(item,index) in data">
                  <td width='150' style='text-align: center;color: black'>{{formatDate(item.datetime)}}</td>
                  <td align='center'><strong style='color: black'>{{ item.five }}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </section>
    <section id="counts" class="counts" style="background-color: #6D6D6D;">
      <div class="section-title" data-aos="fade-up">
        <h2 style="color: #fff;">ຕິດຕາມຜົນການອອກຫວຍໄດ້ກ່ອນໝູ່ ຕິດຕໍ່ເຮົາງ່າຍໆ</h2>
      </div>
      <div class="container">
        <div class="row">
          <div class="offset-xl-1 col-xl-10 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
            <div class="content d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-md-3 d-md-flex align-items-md-stretch">
                  <img width="100%" src="@/assets/images/Chat-icon_1.png" style="padding-bottom: 15px;">
                </div>

                <div class="col-md-3 d-md-flex align-items-md-stretch">
                  <img width="100%" src="@/assets/images/IG-app-icon_1.png" style="padding-bottom: 15px;">
                </div>

                <div class="col-md-3 d-md-flex align-items-md-stretch">
                  <img width="100%" src="@/assets/images/Line-icon_1.png" style="padding-bottom: 15px;">
                </div>

                <div class="col-md-3 d-md-flex align-items-md-stretch">
                  <img width="100%" src="@/assets/images/What-app-icon_1.png" style="padding-bottom: 15px;">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section id="team" class="team section-bg">
      <div class="container">

        <div class="section-title" data-aos="fade-up" >
          <h2 style="color: #fff;">ບັນຫາພາລວຍ</h2>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-12 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="200">
              <div class="member-img">
                <img src="@/assets/images/Ad_1.jpg" class="img-fluid" alt="">
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="100">
              <div class="member-img">
                <img src="@/assets/images/Ad_2.jpg" class="img-fluid" alt="">
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="100">
              <div class="member-img">
                <img src="@/assets/images/Ad_3.jpg" class="img-fluid" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section id="section_1798204912" class="services">
      <div class="container" data-aos="fade-up">
        <div class="section-title" >
          <h2 id="h_1798204912">ຕົວແທນຈຳໜ່າຍທົ່ວປະເທດ</h2>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12 d-flex align-items-stretch">
            <img src="https://www.laospremium.com/assets/img/laos/laodl_dealer_digital3-1355x800.jpg" class="img-fluid" alt="" style="width: 100%;height: 100%;">
          </div>
        </div>
      </div>
    </section>     -->
    <!-- <section id="section_1537631202" class="d-flex align-items-center">
      <div class="container" >
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="col-inner" style="padding:44px 0px 0px 0px;">
              <div class="video video-fit mb" >
                <iframe title="เลขเด็ด ຫວຍດີຈິຕ້ອນ ສະໝັກງ່າຍໆ ລາຍໄດ້ດີ ສະດວກສະບາຍ ມາເປັນຄອບຄົວດຽວກັນກັບພວກເຮົາເດີພີ້ນ້ອງ" 
                width="100%" height="280" 
                src="https://www.youtube.com/embed/JcDAOcK4rdM?feature=oembed&amp;enablejsapi=1&amp;origin=https://laodl.com" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen="">
              </iframe>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="col-inner">
              <h3 style="text-align: left;"></h3>
              <h2 style="text-align: center;"><strong>ປະຫວັດບໍລິສັດ INSEE TRADING</strong></h2>
              <h2 style="text-align: center;"><strong><span style="font-size: 80%;">ຕົວແທນຈຳໜ່າຍ ຫວຍພັດທະນາ ນະຄອນຫຼວງເລກ 3</span></strong></h2>
              <p style="text-align: center;"><span style="font-size: 80%;">&nbsp; &nbsp; ບໍລິສັດ ອິນຊີ ເທຣດດິ້ງ ຈຳກັດຜູ້ດຽວ ໄດ້ຮັບອານຸຍາດ ຈາກກະຊວງການເງິນ ກໍ່ຄື ບໍລິສັດລັດ ວິສາຫະກິດ ຫວຍພັດທະນາ ແຫ່ງ ສ.ປ.ປ.ລາວ ໃຫ້ເປັນຕົວແທນຈຳໜ່າຍ ຫວຍພັດທະນາ ນະຄອນຫຼວງເລກ3, ເລີ່ມຈຳໜ່າຍ ຫວຍແບບຈົດຂາຍ ນັບແຕ່ວັນທີ 17 ກໍລະກົດ 2003 ເປັນຕົ້ນມາ, ຊຶ່ງມີສຳນັກງານໃຫ່ຍຕັ້ງຢູ່ ຖະໜົນ 23 ສິງຫາ, ບ້ານ ໜອງບອນ, ເມືອງ ໄຊເສດຖາ, ນະຄອນຫຼວງເລກ3</span><br>
              <span style="font-size: 80%;">&nbsp; &nbsp; ບໍລິສັດ ອິນຊີ ເທຣດດິ້ງ ຈຳກັດຜູ້ດຽວ ຕົວແທນຈຳໜ່າຍ ຫວຍພັດທະນາ ນະຄອນຫຼວງເລກ3, ແມ່ນວິສາຫະກິດສ່ວນບຸກຄົນຊິ່ງແມ່ນ ບໍລິສັດລັດ ວິສາຫະກິດ ຫວຍພັດທະນາ ເປັນຜູ້ຊີ້ນຳໃນການຈັດຕັ້ງປະຕິບັດ ດ້ານຫວຍພັດທະນາ ພາຍໃຕ້ກົດໜາຍຂອງ ສ.ປ.ປ.ລາວ ມີໜ້າທີ່ໃນການບໍລິການຈຳໜ່າຍຫວຍພັດທະນາ ເລກ 2ໂຕ 3ໂຕ ທາງຕົວແທນຈຳໜ່າຍມີສິດເຕັມສ່ວນໃນການດຳເນີນທຸລະກິດ.<br>
              ທາງຕົວແທນຈຳໜ່າຍໄດ້ດຳເນີນການຂາຍຫວຍແບບພັດທະນາຂື້ນເລື້ອຍໆຢ່າງເຫັນໄດ້ຊັດເຈນເຊັ່ນ:</span></p>
              <ul>
              <li><span style="font-size: 80%;">ປີ 2003 ພັດທະນາຈາກການຈີກມາເປັນຈົດຂາຍ</span></li>
              <li style="text-align: left;"><span style="font-size: 80%;">ປີ 2004 ໄດ້ມີພະລິດຕະພັນໃໝ່ອອກຈຳໜ່າຍກໍ່ຄື ຫວຍຂູດ ແລະ ຈຳໜ່າຍໃບຫວຍສາຍພິເສດເລກ 5ໂຕ ໃນວັນສຳຄັນຕ່າງໆ</span></li>
              <li style="text-align: left;"><span style="font-size: 80%;">ປີ 2007 ໄດ້ຈຳໜ່າຍຫວຍ 4ໂຕເພີ້ມໂຊກ</span></li>
              <li style="text-align: left;"><span style="font-size: 80%;">ປີ 2008 ໄດ້ຈຳໜ່າຍຜະລິດຕະພັນຫວຍໂລໂຕ 5/32</span></li>
              <li style="text-align: left;"><span style="font-size: 80%;">ປີ 2011 ພັດທະນາຈາກການຂາຍແບບຈົດມາເປັນຫວຍແບບດີຈິຕ້ອນ</span></li>
              <li style="text-align: left;"><span style="font-size: 80%;">ປີ 2015 ໄດ້ຮັບອານຸຍາດຈຳໜ່າຍຫວຍດີຈິຕ້ອນ ເລກ 2ໂຕ 3ໂຕ ທົ່ວປະເທດ</span><br>
              <h6></h6>
              <p>ຫວຍດີຈິຕ້ອນ ສາຂາ 3 ອິນຊີ ຕົວແທນຈຳໜ່າຍນະຄອນຫຼວງ ເລກ3 ເປັນຕົວແທນຈຳໜ່າຍໄດ້ທົ່ວປະເທດ</p></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="col-inner" style="padding:44px 0px 0px 0px;">
              <img src="https://www.laospremium.com/assets/img/laos/testimonel-2-1400x441.jpg" class="img-fluid" alt="">
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- <section id="services" class="services">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>ເວັບໄຊທ໌ ກຸ່ມບໍລິສັດ ອິນຊີ</h2>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <img src="https://www.laospremium.com/assets/img/laos/inseeexpress-laodl-800x800.jpg" class="img-fluid" alt="">
              <h4 class="title"><a href="">Insee Express</a></h4>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <img src="https://www.laospremium.com/assets/img/laos/inseegroup-laodl-800x800.jpg" class="img-fluid" alt="">
              <h4 class="title"><a href="">INSEEGROUP</a></h4>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <img src="https://www.laospremium.com/assets/img/laos/onxon.com-laodl-800x800.jpg" class="img-fluid" alt="">
              <h4 class="title"><a href="">ONXON</a></h4>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <img src="https://www.laospremium.com/assets/img/laos/inseetrading-laodl-800x800.jpg" class="img-fluid" alt="">
              <h4 class="title"><a href="">inseeinsurance</a></h4>
            </div>
          </div>
        </div>

      </div>
    </section> -->
    <!-- <section id="faq" class="faq">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>ແຜນທີ່</h2>
        </div>
        <div class="row">
          <div class="offset-lg-1 col-lg-10 col-md-12">
            <iframe src="https://www.google.com/maps/d/embed?mid=10Q-qLZ42d4K2bgqy2VLMBvlsz0c&amp;hl=th" 
                    width="100%" height="480">
            </iframe>
          </div>
        </div>
        
      </div>
    </section> -->
    <footer id="footer">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-lg-12 text-lg-center text-center">
            <div class="copyright" style="color: #fff;">
              Copyright {{getFullYear()}} &copy; <strong>ຫວຍລາວດິຈິຕອນ 12 ราศี VIP</strong>
            </div>
          </div>
        </div>
      </div>
    </footer>
  <a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>
        </div>
  </template>
  
  <script>
  import firebase from 'firebase/app'
  import moment from 'moment-timezone'
  import axios from 'axios'
  import '@/assets/css/odometer-theme-train-station.css';
  
  export default {
    name: 'Home',
    async mounted () {
      await this.initializeServerTime();
     // this.getBackGround()
      this.getLotto()
      this.standby()
      //this.getBanners()
    },
    computed: {
    },
    data () {
      return {
        serverTime: null,
        timeOffset: 0,
        HH:22,
        st:15,
        rt:28,
        re:31,
        AniDiceRandom:false,
        dic: {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
        },
        pictureUrl: '',
        first: '',
        data: [],
        banner: [],
        seconds: 0,
        datetime:'',
      }
    },
    methods: {
      async getTimeServer() {
    try {
      const response = await axios.get('https://us-central1-lotto-landingpage2.cloudfunctions.net/gettimezone');
      return response.data.datetime;
    } catch (error) {
      try {
        const response = await axios.get('https://worldtimeapi.org/api/timezone/Asia/Bangkok');
        return response.data.datetime;
      } catch (error) {
        console.error(error);
        return new Date().toISOString(); // fallback ใช้เวลาของเครื่อง
      }
    }
  },

  // เพิ่ม method ใหม่
  async initializeServerTime() {
    const serverDateTime = await this.getTimeServer();
    const serverTime = new Date(serverDateTime);
    const localTime = new Date();
    this.timeOffset = serverTime.getTime() - localTime.getTime();
    this.serverTime = serverTime;
  },

  // เพิ่ม method ใหม่
  getCurrentServerTime() {
    if (!this.serverTime) return new Date();
    return new Date(new Date().getTime() + this.timeOffset);
  },
      AniDice (dic) {
        this.dic[dic] = setInterval(this.rolldice, 100, dic)
      },
      rolldice (dic) {
        var ranNum = Math.floor(1 + Math.random() * 6)
        document.getElementById('dice' + dic).innerHTML = ranNum
      },
      stopDice (dic) {
        clearInterval(this.dic[dic])
        if (dic == 1) {
          document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(5)
        } else if (dic == 2) {
          document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(4)
        } else if (dic == 3) {
          document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(3)
        } else if (dic == 4) {
          document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(2)
        } else if (dic == 5) {
          document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(1)
        } else if (dic == 6) {
          document.getElementById('dice' + dic).innerHTML = this.first.five.charAt(0)
        }
      },
      async getLotto () {
        const timezone = await this.getTimeServer()
        let data = (await firebase.database().ref('/huay/11').orderByChild('datetime').limitToLast(20).once('value')).val()
        data = _.orderBy(data, ['datetime'], ['desc'])
        if (data) {
          for (const x in data) {
            const d = data[x].datetime.split(' ')
            const date = d[0].split('-')
            const time = d[1].split(':')
            const datetime = Number(date[0] + '' + date[1] + '' + date[2] + '' + time[0] + '' + time[1])
            if (datetime > Number(moment(timezone).format('YYYYMMDDHHmm'))) {
              continue
            }
            if (!this.first) { this.first = data[x] }
            this.data.push(data[x])
          }
        }
        setInterval(this.getLastData, 1000)
      },
      getFullYear () {
        return moment().tz('Asia/Bangkok').format('YYYY')
      },
      convertToLaosMonth(month) {
        switch(month) {
          case "January":
            return "ມັງກອນ";
          case "February":
            return "ກຸມພາ";
          case "March":
            return "ມີນາ";
          case "April":
            return "ເມສາ";
          case "May":
            return "ພຶດສະພາ";
          case "June":
            return "ມິຖຸນາ";
          case "July":
            return "ກໍລະກົດ";
          case "August":
            return "ສິງຫາ";
          case "September":
            return "ກັນຍາ";
          case "October":
            return "ຕຸລາ";
          case "November":
            return "ພະຈິກ";
          case "December":
            return "ທັນວາ";
          default:
            return month;
        }
      },
      formatDate (date) {
        const dd = moment(date).tz('Asia/Bangkok').format('DD')
        const year = moment(date).tz('Asia/Bangkok').format('YYYY')
        const month = this.convertToLaosMonth(moment(date).tz('Asia/Bangkok').format('MMMM'))
        return dd + ' ' + month + ' ' + year
      },
      formatTime(date){
        return moment(date).tz('Asia/Bangkok').format('HH:mm')
      },
      dateToday () {
        return moment().tz('Asia/Bangkok').format('dddd, DD MMMM YYYY')
      },
      async getLastData () {
        const currentTime = this.getCurrentServerTime();
        const timezone = currentTime.toISOString();
        const datas = (await firebase.database().ref('/huay/11').orderByChild('datetime').startAt(moment(timezone).format('YYYY-MM-DD HH:mm')).limitToFirst(1).once('value')).val()
        if (datas) {
          for (const x in datas) {
            const data = datas[x]
            if (data && data.datetime === moment(timezone).format('YYYY-MM-DD HH:mm')) {
              if (this.first.five !== data.five) {
                this.first = data
                // setTimeout(() => {
                //   this.stopDice(1)
                //   this.AniDiceRandom = false
                // }, Number(6 + '000') + 1000)
                // setTimeout(() => {
                //   this.stopDice(2)
                // }, Number(5 + '000') + 1000)
                // setTimeout(() => {
                //   this.stopDice(3)
                // }, Number(4 + '000') + 1000)
                // setTimeout(() => {
                //   this.stopDice(4)
                // }, Number(3 + '000') + 1000)
                // setTimeout(() => {
                //   this.stopDice(5)
                // }, Number(2 + '000') + 1000)
                // setTimeout(() => {
                //   this.stopDice(6)
                // }, Number(1 + '000') + 1000)
              }else{
                this.showNumber();
              }
            } else {
              const t1 = moment(timezone).tz('Asia/Bangkok')
              const t2 = moment(data.datetime).tz('Asia/Bangkok')
              const diff = t2.diff(t1, 'seconds')
              if(diff <= 1800){
                const ddd = this.formatDate(moment(timezone).format('YYYY-MM-DD HH:mm'))
                var ndmy = "ຫວຍລາວດິຈິຕອນ 12 ราศี VIP ວັນທີ "+ddd;
                document.getElementById("dmy1").innerHTML = ndmy + " ເລກອອກ 22:15";
                document.getElementById("dmy2").innerHTML = ndmy + " ເລກອອກ 22:15";
                // document.getElementById("dmy").innerHTML = ndmy + " ເລກອອກ 22:15";
                const number = [
                  '*',
                  '*',
                  '*',
                  '*',
                  '*',
                  '*',
                ];
                if(diff <= 25){
                  number[0] = data.five.toString().charAt(0);
                }
                if(diff <= 20){
                  number[1] = data.five.toString().charAt(1);
                }
                if(diff <= 15){
                  number[2] = data.five.toString().charAt(2);
                }
                if(diff <= 10){
                  number[3] = data.five.toString().charAt(3);
                }
                if(diff <= 5){
                  number[4] = data.five.toString().charAt(4);
                }
                if(diff <= 0){
                  number[5] = data.five.toString().charAt(5);
                }
                for(let i=1;i<=6;i++){
                  if(number[i-1] != "*"){
                    document.getElementById("sl_"+i).setAttribute("data", number[i-1]);
                    document.getElementById("sl_"+i).style.display = "";
                    document.getElementById("rl_"+i).style.display = "none";
                    $('#sl_'+i+'_value').html(number[i-1]);
                  }else{
                    // $('#sl_'+i).html(''+5);
                    document.getElementById("sl_"+i).style.display = "none";
                    document.getElementById("rl_"+i).style.display = "";
                  }
                }
              }else{
                this.showNumber();
              }
              // $('#clock-lottery').FlipClock(diff, {
              //   countdown: true
              // });
              // if(diff <= 1800 && this.AniDiceRandom === false){
              //     this.AniDiceRandom = true;
              //     this.AniDice(1)
              //     this.AniDice(2)
              //     this.AniDice(3)
              //     this.AniDice(4)
              //     this.AniDice(5)
              //     this.AniDice(6)
              //     this.datetime = data.datetime
              // }
            }
          }
        }else{
          this.showNumber();
        }
      },
      showNumber(){
           let ddd = '';
          if(this.datetime){
            ddd = this.formatDate(this.datetime)
          }else{
            ddd = this.formatDate(this.first.datetime) 
          }
          var ndmy = "ຫວຍລາວດິຈິຕອນ 12 ราศี VIP ວັນທີ "+ddd;
          document.getElementById("dmy1").innerHTML = ndmy + " ເລກອອກ 22:15";
          document.getElementById("dmy2").innerHTML = ndmy + " ເລກອອກ 22:15";
          if(this.first && this.first.five){
            const number = [
              this.first.five.toString().charAt(0),
              this.first.five.toString().charAt(1),
              this.first.five.toString().charAt(2),
              this.first.five.toString().charAt(3),
              this.first.five.toString().charAt(4),
              this.first.five.toString().charAt(5),
            ];
            setTimeout(() => {
              for(let i=1;i<=6;i++){
                if(number[i-1] != "*"){
                  document.getElementById("sl_"+i).setAttribute("data", number[i-1]);
                  document.getElementById("sl_"+i).style.display = "";
                  document.getElementById("rl_"+i).style.display = "none";
                  $('#sl_'+i+'_value').html(number[i-1]);
                }else{
                  //$('#sl_'+i).html(''+5);
                  document.getElementById("sl_"+i).style.display = "none";
                  document.getElementById("rl_"+i).style.display = "";
                }
              }
            }, 1000);
          }
      },
      spins(){
        //console.log(this.first);
        //$.getJSON('https://www.laospremium.com/ltresult', (data) => {
          // console.log(data);
          // var dmy2 = data.date.split("/");
          // var ddd = parseInt(dmy2[0]);
          // var mmm = lmonth[parseInt(dmy2[1]) - 1];
          // var yyy = dmy2[2];
          // var ndmy = "ຫວຍລາວດິຈິຕອນ 12 ราศี VIP ວັນທີ " + ddd + " " + mmm + " " + yyy;
          // document.getElementById("dmy1").innerHTML = ndmy + " ເລກອອກ 22:15";
          // document.getElementById("dmy").innerHTML = ndmy + " ເລກອອກ 22:15";
          // const number = [
          //   data.lotto_0,
          //   data.lotto_1,
          //   data.lotto_2,
          //   data.lotto_3,
          //   data.lotto_4,
          //   data.lotto_5,
          // ];
          // for(let i=1;i<=6;i++){
          //   if(number[i-1] != "*"){
          //     document.getElementById("sl_"+i).setAttribute("data", number[i-1]);
          //     document.getElementById("sl_"+i).style.display = "";
          //     document.getElementById("rl_"+i).style.display = "none";
          //     $('#sl_'+i+'_value').html(number[i-1]);
          //   }else{
          //     $('#sl_'+i).html(''+5);
          //     document.getElementById("sl_"+i).style.display = "none";
          //     document.getElementById("rl_"+i).style.display = "";
              
          //   }
          // }
          
        //});
      },
      loadRun(){
        $.getJSON('https://www.laospremium.com/ltresult',  (data) => {
          var dmy2 = data.date.split("/");
          var ddd = parseInt(dmy2[0]);
          var mmm = lmonth[parseInt(dmy2[1]) - 1];
          var yyy = dmy2[2];
          var ndmy = "ຫວຍລາວດິຈິຕອນ 12 ราศี VIP ວັນທີ " + ddd + " " + mmm + " " + yyy;
          document.getElementById("dmy1").innerHTML = ndmy + " ເລກອອກ 22:15";
          document.getElementById("dmy2").innerHTML = "ຫວຍລາວດິຈິຕອນ 12 ราศี VIP ວັນທີ <br>"+ ddd + " " + mmm + " " + yyy;
          document.getElementById("dmy").innerHTML = ndmy + " ເລກອອກ 22:15";
          const number = [
            data.lotto_0,
            data.lotto_1,
            data.lotto_2,
            data.lotto_3,
            data.lotto_4,
            data.lotto_5,
          ];
          for(let i=1;i<=6;i++){
            if(number[i-1] != "*"){
              document.getElementById("sl_"+i).setAttribute("data", number[i-1]);
              document.getElementById("sl_"+i).style.display = "";
              document.getElementById("rl_"+i).style.display = "none";
              $('#sl_'+i).html(number[i-1]);
            }else{
              document.getElementById("sl_"+i).setAttribute("data", '');
              document.getElementById("sl_"+i).style.display = "none";
              document.getElementById("rl_"+i).style.display = "";
            }
          }
        });
        this.showResults();
      },
      startTime() {
        let today = new Date();
        let h = today.getHours();
        let m = today.getMinutes();
        let s = today.getSeconds();
        m = this.checkTime(m);
        s = this.checkTime(s);
        this.checkShow(h);
        console.log(m ,this.rt)
        console.log(m ,this.re)
        if (h == this.HH && m >= this.rt && m < this.re) {
          var IntervalX = setInterval(() => {
            today = new Date();
            h = today.getHours();
            m = today.getMinutes();
            s = today.getSeconds();
            m = this.checkTime(m);
            s = this.checkTime(s);
            if(h==this.HH && m==(this.re-1) && s>=15){
              clearInterval(IntervalX);
              this.showResults();
              var t = setTimeout(this.startTime, 60000);
            } else if (h == this.HH && m >= this.rt && m < this.re) {
              this.loadRun();
            }
          }, 1500);
        }
        this.loadRun();
        var t = setTimeout(this.startTime, 60000);
      },
      checkShow(h) {
        // today = new Date();
        // var DayW = today.getDay();
        // if((DayW == 1) || (DayW == 4)){
        //   document.getElementById("team").style.display = "none";
        // }else{
        //   h = today.getHours();
        //   if(h == HH){
        //     document.getElementById("team").style.display = "";
        //   }else{
        //     document.getElementById("team").style.display = "none";
        //   }
        // }
        if(h == this.HH){
          document.getElementById("team").style.display = "";
        }else{
          document.getElementById("team").style.display = "none";
        }
      
      },
      checkTime(i) {
        if (i < 10) {
          i = "0" + i;
        } 
        return i;
      },
      showResults(){
          var num = ['','','','','','',''];
          const id = "digi";
          setTimeout(() => {
            for(let i=1;i<=6;i++){
              const n = document.getElementById("sl_"+i).getAttribute("data");
              if(n != null){
                num[i] = n;
              }else{
                num[i] = "&nbsp";
              }
            }
            var DD = [];
            DD[6] = ""+num[1] + num[2] + num[3] + num[4] + num[5] + num[6];
            DD[5] = ""+num[2] + num[3] + num[4] + num[5] + num[6];
            DD[4] = ""+num[3] + num[4] + num[5] + num[6];
            DD[3] = ""+num[4] + num[5] + num[6];
            DD[2] = ""+num[5] + num[6];
            DD[1] = ""+num[6];
            if(num[6] != ""){
              for(let i=1;i<=6;i++){
                document.getElementById(i+id).innerHTML = DD[i];
              }          
            }else{
              for(let i=1;i<=6;i++){
                document.getElementById(i+id).innerHTML = "&nbsp;";
              }
            }
          }, 5000);
      },
      standby(){
        for(let j=1;j<=6;j++){
          var outside = document.getElementById("rl_"+j);
          outside.className = 'odog odometer-auto-theme rl_'+j+"";
      
          var inside = document.createElement('div');
          inside.className = 'odometer-inside';
          outside.appendChild(inside);
      
          var span1 = document.createElement('span');
          span1.className = 'odometer-digit';
          inside.appendChild(span1);
      
          var span2 = document.createElement('span');
          span2.className = 'odometer-digit-spacer';
          span1.appendChild(span2);
      
          var span3 = document.createElement('span');
          span3.className = 'odomet runing'+(j-1)+'-number';
          span3.id = "runing"+j;
          span2.appendChild(span3);
      
          outside.appendChild(inside);
        }
        for(let i=1;i<=6;i++){
          document.getElementById("sl_"+i).style.display = "none";
          document.getElementById("rl_"+i).style.display = "";
        }
        this.spins();
        //this.startTime();
      },
    }
  }
  </script>
